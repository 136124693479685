<template>
  <komponen-penyakit-create
    :headerText="headerText"
    :komponenMdl="concreteMdl"
    :jenisKomponen="jenisKomponen"
  />
</template>

<script>
import Komponen from "../models/komponen.js";


export default {
  name: "ConcreteComponentCreate",
  props: ["headerText", "jenisKomponen"],
  data() {
    this.concreteMdl = new Komponen();
    return this.concreteMdl.getObservables();
  },
  components: {
    KomponenPenyakitCreate: () =>
      import("@/apps/penyakit/views/KomponenPenyakitCreate.vue")
  }
};
</script>
