import KomponenBase from "./komponenBase.js"

const modelName = "komponen";
const modelPath = null;

class Komponen extends KomponenBase {
  constructor() {
    super(modelName, {}, [], [], modelPath);
  }
}

export default Komponen;